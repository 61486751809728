import revive_payload_client_McloPkBWfr from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6hcSoZ45MX from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_TkFTJIPWoN from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fp6KizNcPZ from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt-site-config@3.0.4_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@1.81_7tewdlsdils2ttj22v6cqlak3q/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_ne7ElvdYk7 from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fAE6jfv54s from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aJ9Amqu5i7 from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vTlYZsH1U6 from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/mrhs/psychiatry/.nuxt/components.plugin.mjs";
import prefetch_client_t6Fs401Ugu from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_hx4kkgt7cs3lqecdrnegicjxom/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_7FXZ5SJkZw from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt-seo-utils@6.0.3_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@1.81.0_bh2jjuv2poyxndllvm6txqyrmq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_WHxClnWh8e from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt-seo-utils@6.0.3_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@1.81.0_bh2jjuv2poyxndllvm6txqyrmq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_q0lQFIIbyD from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt-seo-utils@6.0.3_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@1.81.0_bh2jjuv2poyxndllvm6txqyrmq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_client_HXJXyif7rg from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.5_rollup@4.28.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import motion_9hpwsUeNVU from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.28.0_vue@3.5.13_typescript@5.7.2_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_JLqYDyxPMs from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.28.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import gtag_client_R70iZRv6jK from "/vercel/path0/apps/mrhs/psychiatry/plugins/gtag.client.js";
import hotjar_client_khHI17zbiJ from "/vercel/path0/apps/mrhs/psychiatry/plugins/hotjar.client.js";
import plyr_client_QNniPXDZgF from "/vercel/path0/apps/mrhs/psychiatry/plugins/plyr.client.js";
import defaults_XQbvVLiYdW from "/vercel/path0/apps/mrhs/psychiatry/node_modules/.pnpm/nuxt-seo-utils@6.0.3_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass@1.81.0_bh2jjuv2poyxndllvm6txqyrmq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_McloPkBWfr,
  unhead_6hcSoZ45MX,
  router_TkFTJIPWoN,
  _0_siteConfig_fp6KizNcPZ,
  payload_client_ne7ElvdYk7,
  navigation_repaint_client_fAE6jfv54s,
  check_outdated_build_client_aJ9Amqu5i7,
  chunk_reload_client_vTlYZsH1U6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_t6Fs401Ugu,
  siteConfig_7FXZ5SJkZw,
  inferSeoMetaPlugin_WHxClnWh8e,
  titles_q0lQFIIbyD,
  plugin_client_HXJXyif7rg,
  motion_9hpwsUeNVU,
  nuxt_plugin_JLqYDyxPMs,
  gtag_client_R70iZRv6jK,
  hotjar_client_khHI17zbiJ,
  plyr_client_QNniPXDZgF,
  defaults_XQbvVLiYdW
]